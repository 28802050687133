import './index.css'
import {useState, useEffect} from 'react'

const Hero = ({data}) => {
    const [showSecondPart, setShowSecondPart] = useState(false)

    useEffect(() => {
        if (data?.hero_title) {
            setTimeout(() => {
                setShowSecondPart(true)
            }, data?.hero_title.length * 50)
        }
    })
    return (
        <div className="hero">
            <div data-aos="fade-down" data-aos-duration="1000" className="img-container">
                <img src={data?.hero_cover} alt="Saida Zahidova" />
            </div>
            {data?.hero_title && (
                <div className="hero-text">
                    {data?.hero_title?.split('').map((letter, index) => {
                        return (
                            <span key={index} style={{animationDelay: `calc(0.05s * ${index})`, animationTimingFunction: 'ease'}}>{letter}</span>
                        )
                    })}
                    <br/>
                    {showSecondPart && data?.hero_title_2?.split('').map((letter, index) => {
                        return (
                            <span key={index} style={{animationDelay: `calc(0.05s * ${index})`, animationTimingFunction: 'ease'}}>{letter}</span>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default Hero
