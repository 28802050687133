import { useFormik } from "formik";
import { useSchema } from "../../../../schemas";
import text from "./text.json";
import toast, { Toaster } from 'react-hot-toast';
import {sendContactData} from "../../../../stores/backend";

const storedContactForm = JSON.parse(localStorage.getItem('contactValues'));
console.log(storedContactForm);

const ContactForm = ({designName , setShowError}) => {

    const { values, errors, isSubmitting, handleChange, handleSubmit , resetForm } =
    useFormik({
      initialValues: {
        fullName: "",
        email: "",
        company: "",
        phone: "",
        message: "",
      },
      validationSchema: useSchema,
        onSubmit : async (values , {resetForm}) =>{
            if(designName.length === 0){
                setShowError(true);
                return;
            }

          sendContactData({...values,service : designName.join(',')})
                .then(res => {
                    toast('Success!', {
                        icon: '👏',
                    });
                    setShowError(false);
                   const timeOut=  setTimeout(()=>{
                        resetForm();
                    }, 1000);
                    return ()=>{
                        clearTimeout(timeOut);
                    }
                })
              .catch(err =>{
                  toast('Error!', {
                      icon: '😤',
                  });
              })
       }
    });
  return (
    <>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={values.fullName}
          onChange={handleChange}
          id="name"
          name="fullName"
          placeholder="FULL NAME *"
          className={`${errors.fullName ? 'input-error' : values.fullName && !errors.fullName ? 'input-success' : ''}`}

        />
        {errors.fullName && <p className="error">{errors.fullName}</p>}
        <input
          type="email"
          value={values.email}
          onChange={handleChange}
          id="email"
          name="email"
          placeholder="E-MAIL *"
          className={` ${errors.email ? 'input-error' : values.email && !errors.email ? 'input-success' : ''}`}
        />
        {errors.email && <p className="error">{errors.email}</p>}
        <input
          type="text"
          value={values.company}
          onChange={handleChange}
          id="company"
          name="company"
          placeholder="COMPANY (OPTIONAL)"
          className={` ${errors.company ? 'input-error' : values.company && !errors.company ? 'input-success' : ''}`}
        />
        {errors.company && <p className="error">{errors.company}</p>}
        <input
          type="number"
          id="number"
          value={values.phone}
          onChange={handleChange}
          name="phone"
          placeholder="PHONE NUMBER (OPTIONAL)"
          className={` ${errors.phone ? 'input-error' : values.phone && !errors.phone ? 'input-success' : ''}`}
        />
        {errors.phone && <p className="error">{errors.phone}</p>}
        <textarea
          placeholder="TELL ME MORE ABOUT YOUR PROJECT *"
          name="message"
          value={values.message}
          onChange={handleChange}
          id="project"
          as="textarea"
          cols="70"
          rows="3"
          className={` ${errors.message ? 'input-error' : values.message && !errors.message ? 'input-success' : ''}`}
        />
        {errors.message && <p className="error">{errors.message}</p>}
          <button  className="formButton" disabled={isSubmitting} type="submit">
          {text.button}
          </button>
         <Toaster
         position="top-center"
         reverseOrder={false}
         />
      </form>
    </>
  );
};
export default ContactForm;
