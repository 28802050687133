import './index.css'
import {useState} from 'react'

const Info = ({data}) => {
    const [hoverEffect2, setHoverEffect2] = useState(false)
    const [hoverEffect3, setHoverEffect3] = useState(false)
    const [bgImage, setBgImage] = useState()
    const [miniBgImage, setMiniBgImage] = useState(data?.info_part_2_cover)

    const deviceWidth = window.innerWidth

    const onHover1 = param => {
        setMiniBgImage(param)
    }

    const onHover2 = param => {
        setHoverEffect2(!hoverEffect2)
        setBgImage(param)
    }

    const onHover3 = param => {
        setHoverEffect3(!hoverEffect3)
        setBgImage(param)
    }

    const bgStyle = {
        backgroundImage: `url(${bgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'

    }

    const miniBgStyle = {
        backgroundImage: `url(${miniBgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'

    }

    return (
        <div style={bgStyle} className={`info ${(hoverEffect2 || hoverEffect3) && 'onhover'}`}>
            <div className="info-section">
                <div className="since">{data?.info_title}</div>
                <div className="main-info">
                    <span className="not-hoverable">{`${data?.info_part_1} `}</span>
                    <span onMouseEnter={() => onHover1(data?.info_part_2_cover)} onMouseLeave={() => onHover1(data?.info_default_cover)} className="hoverable-span">
                        {data?.info_part_2}
                        <span style={miniBgStyle} className="hover-img"></span>
                    </span>
                    <span onMouseEnter={() => onHover2(data?.info_part_3_cover)} onMouseLeave={() =>  onHover2(data?.info_default_cover)} className="hoverable-span">
                        {data?.info_part_3}
                    </span>
                    <span className="not-hoverable">{` ${data?.info_part_4} `}</span>
                    {deviceWidth < 568 && <br/>}
                    <span onMouseEnter={() => onHover3(data?.info_part_5_cover)} onMouseLeave={() => onHover3(data?.info_default_cover)} className="hoverable-span">
                        {data?.info_part_5}
                    </span>
                    <br/>
                    <span className="not-hoverable">{data?.info_part_6}</span>
                </div>
            </div>
            <div className="welcome" data-aos="fade-up" data-aos-duration="1000" >
                <div className="detail">{data?.info_motto_1}</div>
                <div className="motto">{data?.info_motto_2}</div>
            </div>
        </div>
    )
}

export default Info
