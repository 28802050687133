import axios from '../api'

const getWorksData = () => {
    return axios.get('works')
}

const getWorkInner = id => {
    return axios.get(`works/${id}`)
}

const getArchivesData = () => {
    return axios.get('archives')
}

const getArchiveInner = id => {
    return axios.get(`archives/${id}`)
}

const getExperiencesData = () => {
    return axios.get('experiences')
}

const getAboutData = () => {
    return axios.get('about')
}

const getMottoes = () => {
    return axios.get('mottoes')
}

const getSocials = () => {
    return axios.get('social')
}

const getHomePageData = () => {
    return axios.get('home')
}

const getSettingsData = () => {
    return axios.get('settings')
}

const sendContactData = (data) =>{
    return axios.post('contact' , data)
}


export default {
    getWorksData,
    getWorkInner,
    getArchivesData,
    getArchiveInner,
    getExperiencesData,
    getAboutData,
    getMottoes,
    getSocials,
    getHomePageData,
    getSettingsData,
    sendContactData
}
