import '../../assets/scss/content/content.css'
import List from '../../components/pages/archive/list'
import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {getArchives} from '../../stores/backend'
import ContentLoader from '../../components/core/loader'

const Archive = () => {
    const dispatch = useDispatch()
    // const isArchivesFetching = useSelector(state => state.backend.isArchivesFetching)

    const [showCover, setShowCover] = useState(true)

    useEffect(() => {
        dispatch(getArchives())
        setTimeout(() => {
            setShowCover(false)
        }, 2000)
    }, [])

    useEffect(() => {
    }, [])
    return (
        <main className="main">
            {!showCover ? (
                <>
                    <List />
                </>
            ) : (
                <ContentLoader word='Archive.' />
            )}
        </main>
    )
}

export default Archive
