const ArrowRightIcon = props => {
    return (
        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.6667 6.01355L0 6.01355" stroke="#020306"/>
            <path d="M7.22266 1V1C7.7604 2.61311 8.85747 3.98064 10.3156 4.85542L12.2227 5.99958L10.3158 7.14379C8.85755 8.01882 7.76041 9.38662 7.22266 11V11" stroke="#020306"/>
        </svg>
    )
}

const ArrowRightIconWhite = props => {
    return (
        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.6667 6.01355L0 6.01355" stroke="#fff"/>
            <path d="M7.22266 1V1C7.7604 2.61311 8.85747 3.98064 10.3156 4.85542L12.2227 5.99958L10.3158 7.14379C8.85755 8.01882 7.76041 9.38662 7.22266 11V11" stroke="#fff"/>
        </svg>
    )
}

const ArrowRightIconBlack = props => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.4443 7V7C13.9821 8.61311 15.0791 9.98064 16.5372 10.8554L18.4443 11.9996L16.5375 13.1438C15.0792 14.0188 13.9821 15.3866 13.4443 17V17" stroke="#020306"/>
            <path d="M17.8888 12.0137L6.22217 12.0137" stroke="#020306"/>
        </svg>
    )
}

const ArrowLeftIconBlack = props => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5557 7V7C10.0179 8.61311 8.92085 9.98064 7.46276 10.8554L5.55566 11.9996L7.46252 13.1438C8.92077 14.0188 10.0179 15.3866 10.5557 17V17" stroke="#020306"/>
            <path d="M6.11116 12.0137L17.7778 12.0137" stroke="#020306"/>
        </svg>
    )
}

const CloseIcon = props => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 6L6 18" stroke="#3C4F65" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6 6L18 18" stroke="#3C4F65" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

const CheckIcon = props => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill="#00C9BD"/>
            <path d="M17.3334 8L10 15.3333L6.66669 12" stroke="white" strokeWidth="2" strokeLinecap="round"
                strokeLinejoin="round"/>
        </svg>
    )
}

const ErrorIcon = props => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill="#FD4C5C"/>
            <path d="M12 7.7998V12.0665" stroke="white" strokeWidth="2" strokeLinecap="round"
                strokeLinejoin="round"/>
            <path d="M12 16.3335H12.0062" stroke="white" strokeWidth="2" strokeLinecap="round"
                strokeLinejoin="round"/>
        </svg>
    )
}

export {
    ArrowRightIcon,
    ArrowRightIconWhite,
    ArrowRightIconBlack,
    ArrowLeftIconBlack,
    CloseIcon,
    CheckIcon,
    ErrorIcon
}
