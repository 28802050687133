import './index.css'
import {useState, useEffect} from 'react'

const AboutInfo = ({aboutData}) => {
    const [bgImage, setBgImage] = useState()
    const [count, setCount] = useState(0)
    const [animate, setAnimate] = useState(false)
    const [initialTitle, setInitialTitle] = useState(aboutData?.sub_titles[0])

    const deviceWidth = window.innerWidth

    const onHover = param => {
        setBgImage(param)
    }

    const bgStyle = {
        backgroundImage: `url(${bgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'

    }

    useEffect(() => {
        const interval = setInterval(() => {
            setAnimate(false)
            count < aboutData?.sub_titles?.length - 1 ? setCount(count + 1) : setCount(0)
            setAnimate(true)
        }, 2000)
        return () => clearInterval(interval)
    }, [count])

    useEffect(() => {
        setCount(count + 1)
        setTimeout(() => {
            setInitialTitle('')
        }, 2000)
    }, [])

    return (
        <div className="about-info">
            <div data-aos="fade-up" data-aos-duration="1000" className="header">
                <p>{aboutData?.title}</p>
                <h4>
                    {initialTitle && <span dangerouslySetInnerHTML={{__html: initialTitle?.split('#')[0] + '<br/>' + initialTitle?.split('#')[1]}} />}
                    {animate && aboutData?.sub_titles[count]?.split('#')[0]?.split('').map((letter, index) => {
                        return (
                            <span key={index} className="letters reverse" style={{animationDelay: `calc(0.05s * ${index})`, animationTimingFunction: 'ease'}}>{letter}</span>
                        )
                    })}
                    <br />
                    {animate && aboutData?.sub_titles[count]?.split('#')[1]?.split('').map((letter, index) => {
                        return (
                            <span key={index} className="letters" style={{animationDelay: `calc(0.05s * ${index})`, animationTimingFunction: 'ease'}}>{letter}</span>
                        )
                    })}
                </h4>
            </div>
            <div className="info-text">
                <p>
                    <span className="not-hoverable">{`${aboutData?.text_part_1} `}</span>
                    <span onMouseEnter={() => onHover(aboutData?.cover_image)} onMouseLeave={() => onHover()} className="hoverable-span">
                        {aboutData?.text_part_2}
                        <span style={bgStyle} className="hover-img"></span>
                    </span>
                    {deviceWidth < 568 && <br/>}
                    <span className="not-hoverable">{` ${aboutData?.text_part_3} `}</span>
                    <div />
                    <br />
                    <span className="not-hoverable" dangerouslySetInnerHTML={{__html: aboutData?.text_part_4}} />
                </p>
                <div className="sheldon">
                    <span/>
                </div>
            </div>
        </div>
    )
}

export default AboutInfo
