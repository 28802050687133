import text from "./text.json";
import CommunciationSocial from "../communciationsocial";
import {useDispatch, useSelector} from "react-redux";

const Communciation = () => {
  const settings = useSelector(state => state.backend.settings);
  const communicationData = [
    {
      contactName: text.whatsappName,
      contactLink: settings.whatsappLinkText,
      contactMe: settings.whatsapp,
      icon : settings.whatsappVisible
    },
    {
      contactName: text.contactName,
      contactLink: settings.callLinkText,
      contactMe: settings.call,
      icon : settings.callVisible
    },
    {
      contactName: text.emailName,
      contactLink: text.emailLink,
      contactMe: settings.email,
      icon : settings.emailVisible
    },
    {
      contactName: text.telegramName,
      contactLink: text.telegramLink,
      contactMe: settings.telegram,
      icon : settings.telegramVisible
    },
  ];
  return (
    <>
      <p className="communciation--head">{text.communciation}</p>
      {communicationData.map((data, index) => (
      data.icon && <CommunciationSocial
          key={index}
          contactName={data.contactName}
          contactLink={data.contactLink}
          contactMe={data.contactMe}
        />
      ))}
    </>
  );
};
export default Communciation;
