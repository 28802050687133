import {createSlice} from '@reduxjs/toolkit'

export const footerSlice = createSlice({
    name: 'footer',
    initialState: {
        showRights: false
    },
    reducers: {
        setShowRights: (state, {payload}) => {
            state.showRights = payload
        }
    }
})

export const {setShowRights} = footerSlice.actions

export default footerSlice.reducer
