import './index.css'
import '../../assets/scss/content/content.css'
// import sliderData from './data.json'
import InnerHeader from '../../components/pages/work-inner/inner-header'
import InnerFooter from '../../components/pages/work-inner/inner-footer'
import Overview from '../../components/pages/work-inner/overview'
import Template from '../../components/pages/work-inner/template-section'
import SliderSection from '../../components/pages/work-inner/slider-section'
import {getWorkInnerData} from './../../stores/backend'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import ContentLoader from './../../components/core/loader'

const WorkInner = () => {
    const dispatch = useDispatch()
    const splitedUrl = window.location.pathname.split('/')
    const workId = splitedUrl[splitedUrl.length - 1]
    const data = useSelector(state => state.backend.workInnerData)
    // const isWorkFetching = useSelector(state => state.backend.isWorkFetching)
    const [showCover, setShowCover] = useState(true)

    useEffect(() => {
        dispatch(getWorkInnerData(workId))

        setTimeout(() => {
            setShowCover(false)
        }, 2000)
    }, [])
    console.log(data)
    return (
        <main className="main work-inner">
            {!showCover ? (
                <>
                    <InnerHeader data={data} />
                    <Overview data={data} />
                    {data.work?.side_more_detail && data.work?.side_more_image && <Template data={data} />}
                    <InnerFooter data={data} />
                    <SliderSection data={data} />
                </>
            ) : (
                <ContentLoader word='Work.'/>
            )}
        </main>
    )
}

export default WorkInner
