import './index.css'
import text from './text.json'

const Overview = ({data}) => {
    return (
        <div className="overview">
            <div className="desc">
                <h5>{data.work?.description_title || text.overview}</h5>
                <p>{data.work?.description?.replace(/(<([^>]+)>)/ig, '')}</p>
            </div>
            <div className="overview-image">
                <img data-aos="fade-up" data-aos-duration="1000" src={data.work?.middle_image} alt="Saida Zahidova" />
            </div>
        </div>
    )
}

export default Overview
