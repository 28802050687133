import './index.css'
import text from './text.json'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import ContentLoader from './../../../core/loader'
import {getArchiveInnerData} from '../../../../stores/backend'
import SliderArchive from './SliderArchive'

const ArchiveInner = () => {
    const dispatch = useDispatch()
    const splitedUrl = window.location.pathname.split('/')
    const archiveId = splitedUrl[splitedUrl.length - 1]
    const archiveData = useSelector(state => state.backend.archiveInnerData)

    const [showCover, setShowCover] = useState(true)

    useEffect(() => {
        dispatch(getArchiveInnerData(archiveId))

        setTimeout(() => {
            setShowCover(false)
        }, 2000)
    }, [])

    return !showCover ? (
        <div className="archive-inner">
            <div className="basic-infos">
                <h3 className="work-name">
                    {archiveData?.archive?.title?.split('').map((letter, index) => {
                        return (
                            <span key={index} className="letters" style={{animationDelay: `calc(0.05s * ${index})`, animationTimingFunction: 'ease'}}>{letter}</span>
                        )
                    })}
                </h3>

                <div data-aos="fade-up" data-aos-duration="1000" className="infos">
                    {archiveData.archive?.client && (
                        <div className="date">
                            <span>{text.client}</span>
                            <div className="information">{archiveData.archive?.client}</div>
                        </div>
                    )}
                    <div className="services-container">
                        <span>{text.services}</span>
                        <div className="services">
                            {archiveData.archive?.services}
                        </div>
                    </div>

                    <div className="date">
                        <span>{text.completed}</span>
                        <div className="year">{archiveData.archive?.year}</div>
                    </div>

                    {
                        archiveData.archive?.url ?
                            (
                                <div className="date">
                                    <span>{text.built}</span>
                                    <a className="live-link" target="_blank" href={archiveData.archive?.url}>{text.see_live}</a>
                                </div>
                            ) : (
                                <div className="date">
                                    <span>{text.built}</span>
                                    <div className="live-link soon">{text.soon}</div>
                                </div>
                            )
                    }
                </div>
            </div>
            <div className="mobile-slider">
                {archiveData.archive?.gallery?.map(item => {
                    return (
                        <div className="mobile-slider-item-container" key={item}>
                            <div className="mobile-slider-item">
                                <img src={item} alt="Saida Zahidova" />
                            </div>
                        </div>
                    )
                })}
            </div>
            <SliderArchive data={archiveData} />
        </div>
    ) : (
        <ContentLoader word='Archive.' />
    )
}

export default ArchiveInner
