import './index.css'
import text from './text.json'
// import data from './data.json'
import {ArrowRightIcon, ArrowRightIconWhite} from '../../../../icons'
import NavigationHelper from './../../../../utils/navigation'
import {routes} from './../../../../routes'
import {Link} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {useState, useEffect} from 'react'

const List = () => {
    const [bgImg, setBgImg] = useState()
    const [showImg, setShowImg] = useState(false)
    const archives = useSelector(state => state.backend.archives)
    const isArchivesFetching = useSelector(state => state.backend.isArchivesFetching)

    const bgStyle = {
        backgroundImage: `url(${bgImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'

    }

    useEffect(() => {
        window.addEventListener('mousemove', (e) => {
            const {pageX, pageY} = e
            let box = document.querySelector('.box')
            box.style.left = `${pageX - 174}px`
            box.style.top = `${pageY + 5}px`
        })
    }, [])

    return (
        <div className="archive-list">
            <h4>
                {text.title.split('').map((letter, index) => {
                    return (
                        <span key={index} className="letters" style={{animationDelay: `calc(0.05s * ${index})`, animationTimingFunction: 'ease'}}>{letter}</span>
                    )
                })}
                <span className="sup">
                    {isArchivesFetching ? '<span class="loader"></span>' : `(${archives?.length})`}
                </span>
            </h4>
            <div onMouseEnter={() => setShowImg(true)} onMouseLeave={() => setShowImg(false)} className="list-container">
                <ul>
                    {archives?.map(item => {
                        return (
                            <li key={item.id}>
                                <Link onMouseEnter={() => setBgImg(item?.image)} className="archive-link" to={NavigationHelper.getNavigationUrl(routes.archive_inner.path, ':id', item.id)}>
                                    <span className="basic-info">
                                        <span className="name">{item.title}</span>
                                        <span className="year">{item.year}</span>
                                    </span>
                                    <span className="hoverable">
                                        <span className="jobs">
                                            <span className="job-names">{item.services}</span>
                                        </span>
                                        <a>
                                            <ArrowRightIcon className="black-icon"/>
                                            <ArrowRightIconWhite className="white-icon" />
                                        </a>
                                    </span>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
                <div style={bgStyle} onMouseEnter={e => e.stopPropagation()} className={`box ${showImg ? 'scale1' : 'scale0'}`}></div>
            </div>
        </div>
    )
}

export default List
