import Home from './views/home'
import WorkInner from './views/work-inner'
import Archive from './views/archive'
import ArchiveInner from './components/pages/archive/archive-inner'
import About from './views/about'
import Contact from './views/contact'

const routeItem = (path, component) => {
    return {
        path,
        component
    }
}

const routes = {
    first: routeItem('/', Home),
    home: routeItem('/home', Home),
    about: routeItem('/about', About),
    archive: routeItem('/archive', Archive),
    work: routeItem('/work/:id', WorkInner),
    archive_inner: routeItem('/archive-inner/:id', ArchiveInner),
    contact: routeItem('/contact', Contact),
}

const routeArr = Object.values(routes)

export {
    routes,
    routeArr
}
