const navigateToHomePage = () => {
    window.location.href = process.env.REACT_APP_BASE_URL
}

const navigateNotFoundPage = () => {
    window.location.href = `${process.env.REACT_APP_BASE_URL}not-found`
}

const getNavigationUrl = (url, params, value) => {
    return url.replace(params, value)
}

export default {
    navigateToHomePage,
    navigateNotFoundPage,
    getNavigationUrl
}
