const replaceMulCharInString = (_string, _charToReplace, _replaceWith) => {
    for (let i = 0; i < _charToReplace.length; i++) {
        _string = _string.replace(new RegExp(_charToReplace[i], 'gi'), _replaceWith[i])
    }
    return _string
}

const replaceAllChars = (text, char, replacedValue) => {
    return text.replace(new RegExp(char, 'g'), replacedValue)
}

export default {
    replaceMulCharInString,
    replaceAllChars
}
