import { useEffect, useState } from "react";
import ContactTo from "../../../core/contactto";

const CommunciationSocial = ({contactName,contactLink,contactMe}) => {
    const [showEmail, setShowEmail] = useState(false);
    useEffect(() => {
      if (window.innerWidth < 768) {
        setShowEmail(true);
      }
    }, []);
  
    const handleMouseEnter = () => {
      if (window.innerWidth >= 768) {
        setShowEmail(true);
      }
    };
  
    const handleMouseLeave = () => {
      if (window.innerWidth >= 768) {
        setShowEmail(false);
      }
    };
  return (
    <div
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    className={`communciation-form ${showEmail && "active"}`}
  >
    <button className="communciation_name">{contactName}</button>
    <ContactTo contactLink={contactLink} contactMe={contactMe} />
  </div>
  )
}
export default CommunciationSocial