import './index.css'
import text from './text.json'

const InnerHeader = ({data}) => {
    return (
        <div className="inner-header">
            <div className="basic-infos">
                <h3 className="work-name">
                    {data.work?.name?.split('').map((letter, index) => {
                        return (
                            <span key={index} className="letters" style={{animationDelay: `calc(0.05s * ${index})`, animationTimingFunction: 'ease'}}>{letter}</span>
                        )
                    })}
                </h3>
                <div data-aos="fade-up" data-aos-duration="1000" className="infos">
                    {data?.work?.client && (
                        <div className="date">
                            <span>{text.client}</span>
                            <div className="information">{data.work?.client}</div>
                        </div>
                    )}
                    <div className="services-container">
                        <span>{text.services}</span>
                        <div className="services">
                            {data.work?.services.join(', ')}
                        </div>
                    </div>
                    <div className="date">
                        <span>{text.completed}</span>
                        <div className="information">{data.work?.completed}</div>
                    </div>
                    {
                        data.work?.site_url ?
                            (
                                <div className="date">
                                    <span>{text.built}</span>
                                    <a className="live-link" target="_blank" href={data.work?.site_url}>{text.see_live}</a>
                                </div>
                            ) : (
                                <div className="date">
                                    <span>{text.built}</span>
                                    <div className="live-link soon">{text.soon}</div>
                                </div>
                            )
                    }
                </div>
            </div>
            <div className="cover-image">
                <img data-aos="fade-up" data-aos-duration="1000" src={data.work?.main_image} alt="Saida Zahidova" />
            </div>
        </div>
    )
}

export default InnerHeader
