import './index.css'
import text from './text.json'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import NavigationHelper from '../../../../utils/navigation'
import {routes} from './../../../../routes'
import {useEffect, useRef, useState} from 'react'
import {ArrowLeftIconBlack, ArrowRightIconBlack} from '../../../../icons'

const SliderSection = ({data}) => {
    const deviceWidth = window.innerWidth
    const [slidesCount, setSlidesCount] = useState(1.8)
    const sliderRef = useRef()

    useEffect(() => {
        deviceWidth < 768 && setSlidesCount(1.1)
    }, [])

    const settings = {
        dots: false,
        arrows:true,
        infinite: false,
        speed: 500,
        slidesToShow: slidesCount,
        autoplaySpeed: 2000,
        pauseOnHover: false,
        slidesToScroll: 1
    }

    return (
        <>
            <div className="slider">
                <div id="slider">
                    <Slider ref={sliderRef} {...settings}>
                        {data.work?.gallery?.map(item => {
                            return (
                                <div className="item-container" key={item}>
                                    <div className="img">
                                        <img className="slider-img" src={item} alt="Saida Zahidova" />
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
                <div className="arrows-container">
                    <div className="arrows">
                        <span onClick={() => sliderRef.current.slickPrev()}>
                            <ArrowLeftIconBlack stroke="#020306" />
                        </span>
                        <span onClick={() => sliderRef.current.slickNext()}>
                            <ArrowRightIconBlack stroke="#020306" />
                        </span>
                    </div>
                </div>
                <div className="next-container">
                    <div className="name">{data?.next_work ? data?.next_work?.name : data?.prev_work?.name}</div>
                    <a href={NavigationHelper.getNavigationUrl(routes.work.path, ':id', data?.next_work ? data?.next_work?.id : data?.prev_work?.id)}>{text.view_project}</a>
                </div>
            </div>
        </>
    )
}

export default SliderSection
