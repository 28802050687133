import './index.css'

const ContentLoader = ({word, className}) => {
    const url = window.location.href
    return (
        <div className="content-loader">
            <span className={`loader ${(!url.includes('selected-works') && className) && 'spin-word'}`}>
                <span dangerouslySetInnerHTML={{__html: word}} />
            </span>
        </div>
    )
}

export default ContentLoader
