import React from "react";
const ContactTo = ({ contactLink, contactMe }) => {
  return (
    <a
      target='_blank'
      rel="noopener noreferrer" 
      href={contactLink}
      className="communciation_text"
    >
      {contactMe}
    </a>
  );
};

export default ContactTo;
