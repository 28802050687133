import './index.css'
import text from './text.json'
import {useEffect, useState} from 'react'
import  {useDispatch, useSelector} from 'react-redux'
import {setMobileMenuIsActive} from '../../../stores/header'
import {getSocials} from '../../../stores/backend'

const Footer = ({scrolling}) => {
    const showRights = useSelector(state => state.footer.showRights)
    const socials = useSelector(state => state.backend.socials)
    const azerbaijanOffset = 4
    const userTimezoneOffset = new Date().getTimezoneOffset() / 60
    const [date, setDate] = useState(new Date(Date.now() + (azerbaijanOffset + userTimezoneOffset) * 60 * 60 * 1000))

    const dispatch = useDispatch()
    const currentYear = date.getFullYear()
    const currentHour = date.getHours()
    let currentMinute = date.getMinutes()
    if (currentMinute < 10) {
        currentMinute = `0${currentMinute}`
    }
    const ampm = currentHour >= 12 ? 'PM' : 'AM'

    const getModifiedHour = () => {
        return currentHour > 12 ? currentHour - 12 < 10 ? `0${currentHour - 12}` : currentHour - 12 : currentHour
    }

    useEffect(() => {
        dispatch(getSocials())
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setDate(new Date(Date.now() + (azerbaijanOffset + userTimezoneOffset) * 60 * 60 * 1000))
        }, 1000)
    }, [date])

    return (
        <footer className="footer bg-transparent">
            <div className={`rights ${showRights && 'show-rights'}`}>{`© ${currentYear} ALL RIGHTS RESERVED`}</div>
            <div className="footer-container">
                <div className="location">
                    <div className="place">{`${text.city}, ${text.country}`}</div>
                    <div className="time">{`${getModifiedHour()} : ${currentMinute} ${ampm} GMT+${azerbaijanOffset}`}</div>
                </div>
                <div className="footer-menu">
                    <ul>
                        {socials?.map((item, index) => {
                            return (
                                <li key={index} onClick={() => dispatch(setMobileMenuIsActive(false))}>
                                    <a target="_blank" href={item?.url}>{item?.title}</a>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </footer>
    )
}

export default Footer
