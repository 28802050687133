import {createSlice} from '@reduxjs/toolkit'

export const headerSlice = createSlice({
    name: 'header',
    initialState: {
        error: '',
        success: '',
        isWorkInnerPage: false,
        mobileMenuIsActive: false
    },
    reducers: {
        setIsWorkInnerPage: (state, {payload}) => {
            state.isWorkInnerPage = payload
        },
        setMobileMenuIsActive: (state, {payload}) => {
            state.mobileMenuIsActive = payload
        },
        setError: (state, {payload}) => {
            state.error = payload
        },
        setSuccess: (state, {payload}) => {
            state.success = payload
        }
    }
})

export const {setIsWorkInnerPage, setMobileMenuIsActive, setError, setSuccess} = headerSlice.actions

export default headerSlice.reducer
