import Communciation from "../communciation";
import ProjectDiscuss from "../projectdiscuss";
import "./index.scss";
import text from "./text.json";

const ContactsMe = () => { 
  return (
    <div className="Contacts--Me">
      <h4 className="contact_head">
        {text.title.split("").map((letter, index) => {
          return (
            <span
              key={index}
              className="letters"
              style={{
                animationDelay: `calc(0.05s * ${index})`,
                animationTimingFunction: "ease",
              }}
            >
              {letter}
            </span>
          );
        })}
      </h4>
      <div className="contact--container">
        <div className="contacts--all">
        <div className="contacts--left">
            <Communciation  
            />
        </div>
        <div className="contacts--right">
          <ProjectDiscuss/>
        </div>
        </div>
      </div>
    </div>
  );
};
export default ContactsMe;
