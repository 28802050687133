import "./index.scss";
import text from "./text.json";
import data from "./data.json";
import { Link, useLocation } from "react-router-dom";
import { routes } from "./../../../routes";
import TextHelper from "./../../../utils/text";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ArrowRightIcon } from "../../../icons";
import Footer from "./../footer";
import {
  setIsWorkInnerPage,
  setMobileMenuIsActive,
} from "./../../../stores/header";
import { getWorks, getSettings } from "../../../stores/backend";

const Header = ({ scrolling }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const isWorkInnerPage = useSelector((state) => state.header.isWorkInnerPage);
  const mobileMenuIsActive = useSelector(
    (state) => state.header.mobileMenuIsActive
  );
  const isWorksFetching = useSelector((state) => state.backend.isWorksFetching);
  const works = useSelector((state) => state.backend.works);

  const [showEmail, setShowEmail] = useState(false);
  const [logoText, setLogoText] = useState(text.logo_text);
  const [delay, setDelay] = useState("2s");

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const openMobileMenu = () => {
    !mobileMenuIsActive
      ? dispatch(setMobileMenuIsActive(true))
      : dispatch(setMobileMenuIsActive(false));
    setShowEmail(false);
  };

  const replaceChars = (text) => {
    return TextHelper.replaceAllChars(
      text,
      ":count",
      isWorksFetching ? '<span class="loader"></span>' : `(${works?.length})`
    );
  };

  const animateStyle = {
    animation: "write-16 1.2s steps(16) forwards",
  };

  const onHover = () => {
    setLogoText("");
    setDelay("0s");
    setLogoText(text.designer);
  };

  const onBlur = () => {
    setLogoText("");
    setDelay("0s");
    setLogoText(text.logo_text);
  };

  useEffect(() => {
    dispatch(getWorks());
    dispatch(getSettings());
    dispatch(setMobileMenuIsActive(false));
    location.pathname.includes("/work/")
      ? dispatch(setIsWorkInnerPage(true))
      : dispatch(setIsWorkInnerPage(false));
  }, [location]);


  return (
    <header
      className={
        !mobileMenuIsActive
          ? "bg-transparent"
          : !mobileMenuIsActive && isWorkInnerPage
          ? "bg-light-brown"
          : ""
      }
    >
      <div className="header-container">
        <Link to={routes.home.path} className="logo">
          {logoText.length > 0 && (
            <span
              key={logoText}
              onMouseEnter={onHover}
              onMouseLeave={onBlur}
              style={{ ...animateStyle, animationDelay: delay }}
              className="current"
            >
              {logoText}
            </span>
          )}
        </Link>
        <div className="menu-container">
          <ul
            className={
              (location.pathname === "/about" ||
                location.pathname === "/archive" ||
                location.hash.includes("#selected-works")) &&
              "active"
            }
          >
            {data.menu.map((item) => {
              return (
                <li
                  className={
                    (location.pathname === item.url ||
                      location.hash.includes(item.url)) &&
                    "active"
                  }
                  key={item.url}
                >
                  <a
                    href={item.url}
                    dangerouslySetInnerHTML={{
                      __html: replaceChars(item.name),
                    }}
                  />
                </li>
              );
            })}
          </ul>
          <div
            onMouseEnter={() => setShowEmail(!showEmail)}
            onMouseLeave={() => setShowEmail(!showEmail)}
            className={`header-email-section ${showEmail && "active"}`}
          >
            <Link className="email-address" to="/contact">
              {text.email_address}
            </Link>
            <Link to="/contact" className="email-text">{text.email_text}</Link>
          </div>
        </div>
        <div className="mobile-menu">
          <div onClick={openMobileMenu} className="icon-container">
            <div className={`menu-icon ${mobileMenuIsActive && "active"}`}>
              <input className="menu-icon__cheeckbox" type="checkbox" />
              <div>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
          <div
            onClick={stopPropagation}
            className={`mobile-menu-content ${mobileMenuIsActive && "active"}`}
          >
            <ul className="menu-list">
              {data.menu.map((item) => {
                return (
                  <li
                    key={item.url}
                    onClick={() => dispatch(setMobileMenuIsActive(false))}
                  >
                    <a href={item.url}>
                      <span
                        className="link-text"
                        dangerouslySetInnerHTML={{
                          __html: replaceChars(item.name),
                        }}
                      />
                      <span className="link-arrow">
                        <ArrowRightIcon />
                      </span>
                    </a>
                  </li>
                );
              })}
            </ul>
            <div>
              <div className="header-email-section active">
                <Link className="email-address" to="/contact">
                  {text.email_text}
                </Link>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
