import {BrowserRouter, Route, Routes} from 'react-router-dom'
import {routeArr} from './routes'
import Header from './components/layouts/header'
import Footer from './components/layouts/footer'
import {setShowRights} from './stores/footer'
import {useEffect, useState, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import Alert from './components/core/alert'
import Aos from 'aos'
import 'aos/dist/aos.css'
import NotFound from './components/pages/not-found'

const App = () => {
    const url = window.location.href
    const isWorkInnerPage = useSelector(state => state.header.isWorkInnerPage)
    const error = useSelector(state => state.header.error)
    const success = useSelector(state => state.header.success)
    const containerRef = useRef()
    const dispatch = useDispatch()
    const [isEndOfPage, setIsEndOfPage] = useState(false)

    const handleScroll = () => {
        if (containerRef.current.clientHeight === Math.floor(window.pageYOffset + window.innerHeight) && window.pageYOffset !== 0) {
            setIsEndOfPage(true)
            return
        }
        setIsEndOfPage(false)
    }

    useEffect(() => {
        setIsEndOfPage(false)
        !url.includes('selected-works') && window.scrollTo(0, 0)
        dispatch(setShowRights(false))
    }, [url, isWorkInnerPage])

    useEffect(() => {
        if (isEndOfPage) {
            dispatch(setShowRights(true))
            return
        }
        dispatch(setShowRights(false))
    }, [isEndOfPage])

    useEffect(() => {
        Aos.init({once: true})
    }, [])

    return (
        <>
            {success && <Alert type="success" text={success} duration={process.env.REACT_APP_ALERT_DURATION}/>}
            {error && <Alert type="error" text={error} duration={process.env.REACT_APP_ALERT_DURATION}/>}
            <BrowserRouter>
                <Routes>
                    {routeArr.map(item => (
                        <Route index exact path={item.path} key={item.path} element={
                            <div onWheel={handleScroll} ref={containerRef} className="container">
                                <Header />
                                <item.component/>
                                <Footer />
                            </div>
                        }/>
                    ))}
                    <Route path="*" element={<NotFound />}/>
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default App
