import './index.css'
import text from './text.json'
import TextHelper from './../../../../utils/text'

const Experience = ({experiences}) => {
    const deviceWidth = window.innerWidth

    const monthsLong = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]

    const monthsShort = [
        'JAN',
        'FEB',
        'MAR',
        'APR',
        'MAY',
        'JUN',
        'JUL',
        'AUG',
        'SEP',
        'OCT',
        'NOV',
        'DEC'
    ]

    return (
        <div className="experience">
            <div data-aos="fade-up" data-aos-duration="1000" className="header">
                <p>{text.sub_header}</p>
                <h4>{text.header}</h4>
            </div>
            <div data-aos="fade-up" data-aos-duration="1000" className="list-container">
                <ul>
                    {experiences?.map(item => {
                        return (
                            <li key={item.id}>
                                <span className="company">
                                    {item.title}
                                </span>
                                <span className="from-to">{deviceWidth > 568 ? item.date : TextHelper.replaceMulCharInString(item.date, monthsLong, monthsShort)}</span>
                                <div className="job-container">
                                    <span className="job">{item.position}</span>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}

export default Experience
