import './index.css'
import text from './text.json'
import {Link, useLocation} from 'react-router-dom'
import {routes} from './../../../../routes'
import NavigationHelper from './../../../../utils/navigation'
import TextHelper from './../../../../utils/text'
import {useSelector} from 'react-redux'
import {useEffect, useRef} from 'react'

const SelectedWorks = () => {
    const currentYear = new Date().getFullYear()
    const works = useSelector(state => state.backend.works)
    const worksCount = TextHelper.replaceAllChars(text.title, ':count', works.length)
    const location = useLocation()
    const worksRef = useRef(null)

    const returnSplitedYear = number => {
        const year = number.toString()
        return `${year[0]}${year[1]} ~ ${year[2]}${year[3]}`
    }

    useEffect(() => {
        location.hash.includes('selected-works') ? worksRef.current?.scrollIntoView({behavior: 'smooth'}) : window.scrollTo(0, 0)
    }, [location])

    return (
        <div ref={worksRef} className="selected-works" id="selected-works">
            <div data-aos="fade-up" data-aos-duration="1000" className="header">
                <div className="date">{returnSplitedYear(currentYear)}</div>
                <div className="title"  dangerouslySetInnerHTML={{__html: worksCount}} />
            </div>
            <div className="works">
                {works?.map(item => {
                    return (
                        <Link key={item?.id} to={NavigationHelper.getNavigationUrl(routes.work.path, ':id', item?.id)} className="work">
                            <div className="hover-name">
                                {item?.name?.split('').map((letter, index) => {
                                    return (
                                        <span key={index} style={{animationDelay: `calc(0.05s * ${index})`, animationTimingFunction: 'ease'}}>{letter}</span>
                                    )
                                })}
                            </div>
                            <div className="infos" >
                                <div className="jobs">
                                    {item?.services.map(job => {
                                        return (
                                            <span key={job} className="job">{job}</span>
                                        )
                                    })}
                                </div>
                                <div className="footer">
                                    <div className="name">{item?.name}</div>
                                    <div className="date">{item?.completed}</div>
                                </div>
                            </div>
                            <div className="img-container">
                                <img className="work-img" src={item?.cover_image} alt="Saida Zahidova" />
                            </div>
                        </Link>
                    )
                })}
            </div>
        </div>
    )
}

export default SelectedWorks
