import {Link} from "react-router-dom";

const MailTo = ({email, subject, body, classname, text}) => {
    return (
        <Link to={"/contact"} className={classname}>
            {text}
        </Link>
    )
}

export default MailTo
