import './index.css'
import text from './text.json'

const InnerFooter = ({data}) => {
    return (
        <div className="inner-footer">
            <div data-aos="fade-up" data-aos-duration="1000" className="text">
                <span>{data.work?.bottom_title || text.more}</span>
                <p>{data.work?.bottom_more_detail?.replace(/(<([^>]+)>)/ig, '')}</p>
            </div>
            <div className="bottom-review-image">
                <img data-aos="fade-up" data-aos-duration="1000" src={data.work?.last_image} alt="Saida Zahidova" />
            </div>
        </div>
    )
}

export default InnerFooter
