import { useState,useEffect } from "react";
import text from "./text.json";
import ContactForm from "../contactform";
const ProjectDiscuss = () => {
  const DESIGN_NAMES = {
    isClickedFirst : text.designFirst,
    isClickedSecond: text.designSecond,
    isClickedThird: text.designThird,
    isClickedFourth: text.designFourth
  }
  const [designClicked, setDesignClicked] = useState({
    isClickedFirst:false,
    isClickedSecond:false,
    isClickedThird:false,
    isClickedFourth:false
  });
  const [clickedDesignNames , setClickedDesignNames] = useState([]);

  const [isServicesError , setIsServicesError]= useState(false);

  const handleDesignNameClick = (designName) => {
    const updateDesignName = {
      ...designClicked,
      [designName]: !designClicked[designName],
    };
    setDesignClicked(updateDesignName);
    if(clickedDesignNames.includes(DESIGN_NAMES[designName])){
      setClickedDesignNames(clickedDesignNames.filter(item => item !== DESIGN_NAMES[designName]));
    }
    else {
      setClickedDesignNames([...clickedDesignNames, DESIGN_NAMES[designName]]);
    }
    localStorage.setItem('designClicked', JSON.stringify(updateDesignName));
  };
  
  useEffect(() => {
    let designClicked = localStorage.getItem('designClicked');
    const storedDesignClicked = designClicked ? JSON.parse(localStorage.getItem('designClicked')) : {};
    if (storedDesignClicked) {
      setDesignClicked(storedDesignClicked);
    }
    if(Object.values(storedDesignClicked).some(item => item)){
      setClickedDesignNames(Object.entries(storedDesignClicked)
          .map(([key , value]) => value && DESIGN_NAMES[key])
          .filter(Boolean));
    }
  }, []);

  useEffect(()=>{
    if (clickedDesignNames.length > 0 && isServicesError){
      setIsServicesError(false)
    }
  },[clickedDesignNames])

  return (
    <>
      <p className="discuss--head">{text.title}</p>
      <ul className="discuss--design">
        <div className="left-design">
        <li
            className={`design_name ${designClicked.isClickedFirst ? "clicked" : ""}`}
            onClick={() => handleDesignNameClick("isClickedFirst" , text.designFirst)}
          >
            {text.designFirst}
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </li>

          <li
            className={`design_name ${designClicked.isClickedSecond ? "clicked" : ""}`}
            onClick={() => handleDesignNameClick("isClickedSecond",text.designSecond)}
          >
            {text.designSecond}
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </li>
        </div>
        <div className="right-design">
        <li
            className={`design_name ${designClicked.isClickedThird ? "clicked" : ""}`}
            onClick={() => handleDesignNameClick("isClickedThird",text.designThird)}
          >
            {text.designThird}
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </li>
          <li
            className={`design_name ${designClicked.isClickedFourth ? "clicked" : ""}`}
            onClick={() => handleDesignNameClick("isClickedFourth",text.designFourth)}
          >
            {text.designFourth}
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </li>
        </div>
      </ul>
      {isServicesError && <p className="error">{text.showServicesError}</p>}
      <ContactForm setShowError={setIsServicesError} designName={clickedDesignNames}/>
    </>
  );
};
export default ProjectDiscuss;
