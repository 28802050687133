import * as yup from "yup";
// const nameRules = /^[a-zA-Z\s]+$/;
const emailRules = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const companyRules = /^(?=.*[a-zA-Z])([a-zA-Z0-9\s*+]+)$/;
const phoneNumberRules = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const useSchema = yup.object().shape({
    fullName:yup
    .string()
    .trim()
    .min(3,"Full Name must contain at least 3 characters")
    .required("Please enter your Full Name"),
    email:yup
    .string()
    .trim()
    .matches(emailRules,{
        message:"Invalid email address - must be within validation rules",
    })
    .required("Please enter your email"),
    company:yup
    .string()
    .trim()
    .min(2,"Company name must contain at least 2 characters")
    .matches(companyRules,{
        message:"wrong company name"
    }),
   phone:yup
   .string()
   .trim()
   .min(7, "Must be exactly 7 at least digits")
   .matches(phoneNumberRules, {
     message: "Invalid phone number format"
   }),
   message:yup
   .string()
   .trim()
   .min(5,"Message text must contain at least 5 characters")
   .required("Please tell about your project")
})
