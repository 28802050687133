import {createSlice} from '@reduxjs/toolkit'
import ApiService from '../../api/backend'
import {setIsWorkInnerPage} from './../header'
import NavigationHelper from './../../utils/navigation'

export const backendSlice = createSlice({
    name: 'backend',
    initialState: {
        isWorksFetching: false,
        isWorkFetching: false,
        works: [],
        workInnerData: {},
        isArchivesFetching: false,
        isArchiveFetching: false,
        archives: [],
        archiveInnerData: {},
        experiences: [],
        isExperiencesFetching: false,
        mottoes: [],
        aboutData: {},
        isAboutDataFetching: false,
        socials: [],
        homePageData: {},
        setIsHomePageDataFetching: false,
        settings: {},
    },
    reducers: {
        setWorks: (state, {payload}) => {
            state.works = payload
        },
        setWorkInnerData: (state, {payload}) => {
            state.workInnerData = payload
        },
        setIsWorksFetching: (state, {payload}) => {
            state.isWorksFetching = payload
        },
        setIsWorkFetching: (state, {payload}) => {
            state.isWorkFetching = payload
        },
        setArchives: (state, {payload}) => {
            state.archives = payload
        },
        setArchiveInnerData: (state, {payload}) => {
            state.archiveInnerData = payload
        },
        setIsArchivesFetching: (state, {payload}) => {
            state.isArchivesFetching = payload
        },
        setIsArchiveFetching: (state, {payload}) => {
            state.isArchiveFetching = payload
        },
        setExperiences: (state, {payload}) => {
            state.experiences = payload
        },
        setIsExperiencesFetching: (state, {payload}) => {
            state.isExperiencesFetching = payload
        },
        setMottoes: (state, {payload}) => {
            state.mottoes = payload
        },
        setAboutData: (state, {payload}) => {
            state.aboutData = payload
        },
        setIsAboutDataFetching: (state, {payload}) => {
            state.isAboutDataFetching = payload
        },
        setSocials: (state, {payload}) => {
            state.socials = payload
        },
        setHomePageData: (state, {payload}) => {
            state.homePageData = payload
        },
        setIsHomePageDataFetching: (state, {payload}) => {
            state.isHomePageDataFetching = payload
        },
        setSettings: (state, {payload}) => {
            state.settings = payload
        }
    }
})

export const {
    setWorks,
    setWorkInnerData,
    setIsWorksFetching,
    setIsWorkFetching,
    setArchives,
    setArchiveInnerData,
    setIsArchivesFetching,
    setIsArchiveFetching,
    setExperiences,
    setIsExperiencesFetching,
    setIsAboutDataFetching,
    setMottoes,
    setAboutData,
    setSocials,
    setIsHomePageDataFetching,
    setHomePageData,
    setSettings,

} = backendSlice.actions

export default backendSlice.reducer

export const getWorks = () => {
    return async dispatch => {
        dispatch(setIsWorksFetching(true))
        try {
            const {data} = await ApiService.getWorksData()
            dispatch(setWorks(data.data))
            dispatch(setIsWorksFetching(false))
        } catch (e) {
            NavigationHelper.navigateNotFoundPage()
        }
    }
}

export const getWorkInnerData = id => {
    return async dispatch => {
        dispatch(setIsWorkFetching(true))
        dispatch(setIsWorkInnerPage(false))
        try {
            const {data} = await ApiService.getWorkInner(id)
            dispatch(setWorkInnerData(data.data))
            dispatch(setIsWorkFetching(false))
            dispatch(setIsWorkInnerPage(true))
        } catch (e) {
            NavigationHelper.navigateNotFoundPage()
        }
    }
}

export const getArchives = () => {
    return async dispatch => {
        dispatch(setIsArchivesFetching(true))
        try {
            const {data} = await ApiService.getArchivesData()
            dispatch(setArchives(data.data))
            dispatch(setIsArchivesFetching(false))
        } catch (e) {
            NavigationHelper.navigateNotFoundPage()
        }
    }
}

export const getArchiveInnerData = id => {
    return async dispatch => {
        dispatch(setIsArchiveFetching(true))
        try {
            const {data} = await ApiService.getArchiveInner(id)
            dispatch(setArchiveInnerData(data.data))
            dispatch(setIsArchiveFetching(false))
        } catch (e) {
            NavigationHelper.navigateNotFoundPage()
        }
    }
}

export const getExperiences = () => {
    return async dispatch => {
        dispatch(setIsExperiencesFetching(true))
        try {
            const {data} = await ApiService.getExperiencesData()
            dispatch(setExperiences(data.data))
            dispatch(setIsExperiencesFetching(false))
        } catch (e) {
            NavigationHelper.navigateNotFoundPage()
        }
    }
}

export const getMottoes = () => {
    return async dispatch => {
        try {
            const {data} = await ApiService.getMottoes()
            dispatch(setMottoes(data.data))
        } catch (e) {
            NavigationHelper.navigateNotFoundPage()
        }
    }
}

export const getAboutData = () => {
    return async dispatch => {
        dispatch(setIsAboutDataFetching(true))
        try {
            const {data} = await ApiService.getAboutData()
            dispatch(setAboutData(data.data))
            dispatch(setIsAboutDataFetching(false))
        } catch (e) {
            NavigationHelper.navigateNotFoundPage()
        }
    }
}

export const getSocials = () => {
    return async dispatch => {
        try {
            const {data} = await ApiService.getSocials()
            dispatch(setSocials(data.data))
        } catch (e) {
            NavigationHelper.navigateNotFoundPage()
        }
    }
}

export const getHomePageData = id => {
    return async dispatch => {
        dispatch(setIsHomePageDataFetching(true))
        try {
            const {data} = await ApiService.getHomePageData()
            dispatch(setHomePageData(data.data))
            dispatch(setIsHomePageDataFetching(false))
        } catch (e) {
            NavigationHelper.navigateNotFoundPage()
        }
    }
}

export const getSettings = () => {
    return async dispatch => {
        try {
            const {data} = await ApiService.getSettingsData()
            dispatch(setSettings(data.data))
        } catch (e) {
            NavigationHelper.navigateNotFoundPage()
        }
    }
}

export const sendContactData = async (data) =>{
    return await ApiService.sendContactData(data);
}