import '../../assets/scss/content/content.css'
import Experience from './../../components/pages/about/experience'
import AboutInfo from './../../components/pages/about/about-info'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getExperiences, getAboutData} from '../../stores/backend'
import ContentLoader from './../../components/core/loader'

const About = () => {
    const dispatch = useDispatch()
    const experiences = useSelector(state => state.backend.experiences)
    const aboutData = useSelector(state => state.backend.aboutData)
    // const isExperiencesFetching = useSelector(state => state.backend.isExperiencesFetching)
    // const isAboutDataFetching = useSelector(state => state.backend.isAboutDataFetching)

    const [showCover, setShowCover] = useState(true)

    useEffect(() => {
        dispatch(getExperiences())
        dispatch(getAboutData())

        setTimeout(() => {
            setShowCover(false)
        }, 2000)
    }, [])
    return (
        <main className="main">
            {!showCover ? (
                <>
                    <AboutInfo aboutData={aboutData} />
                    <Experience experiences={experiences} />
                </>
            ) : (
                <ContentLoader word='About Me.'/>
            )}
        </main>
    )
}

export default About
