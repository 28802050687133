import './index.css'
import text from './text.json'
import {useSelector, useDispatch} from 'react-redux'
import {useEffect, useState} from 'react'
import {getMottoes} from '../../../../stores/backend'
import Mailto from './../../../../components/core/mailto'

const EmailSection = () => {
    const mottoes = useSelector(state => state.backend.mottoes)

    const [count, setCount] = useState(0)
    const [animate, setAnimate] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        const interval = setInterval(() => {
            setAnimate(false)
            count < mottoes?.length - 1 ? setCount(count + 1) : setCount(0)
            setAnimate(true)
        }, 2000)
        return () => clearInterval(interval)
    }, [count])

    useEffect(() => {
        setCount(count + 1)
        dispatch(getMottoes())
    }, [])

    return (
        <div className="email-section">
            <div className="flip-container">
                <div className="flip">
                    <div className="title-container">
                        <div className="title">
                            <span>{text.quote_start}</span>
                            <br />
                            <span>
                                {animate && mottoes[count]?.split('').map((letter, index) => {
                                    return (
                                        <span key={index} className="letters" style={{animationDelay: `calc(0.05s * ${index})`, animationTimingFunction: 'ease'}}>{letter}</span>
                                    )
                                })}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <span className="email">
                <Mailto classname="email-text" email={text.email_address} subject={text.email_subject} text={text.email_text} />
            </span>
        </div>
    )
}

export default EmailSection
