import {configureStore} from '@reduxjs/toolkit'
import headerReducer from './header'
import footerReducer from './footer'
import backendReducer from './backend'

export default configureStore({
    reducer: {
        header: headerReducer,
        footer: footerReducer,
        backend: backendReducer
    }
})
