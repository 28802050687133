import './index.css'
import {CheckIcon, CloseIcon, ErrorIcon} from './../../../icons'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {setError, setSuccess} from './../../../stores/header'

const Alert = ({text, type, duration}) => {
    const success = useSelector(state => state.header.success)
    const error = useSelector(state => state.header.error)
    const dispatch = useDispatch()
    const icons = {
        success: <CheckIcon/>,
        error: <ErrorIcon/>
    }
    const [className, setClassName] = useState('')
    const closeAlert = () => {
        setClassName('none')
        setTimeout(() => {
            success && dispatch(setSuccess(''))
            error && dispatch(setError(''))
        }, 1000)
    }
    useEffect(async() => {
        setTimeout(() => {
            closeAlert()
        }, duration)
    }, [text])
    return (
        <div className={`alert ${className} alert-${type}`}>
            <span>
                {icons[type]}
            </span>
            <span>{text}</span>
            <span onClick={closeAlert}>
                <CloseIcon/>
            </span>
        </div>
    )
}

export default Alert
