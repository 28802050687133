import {useEffect} from 'react'
import './index.css'
import text from './text.json'
import {routes} from './../../../routes'

const NotFound = () => {
    useEffect(() => {
        setTimeout(() => {
            window.location.replace(routes.home.path)
        }, 3000)
    }, [])
    return (
        <div className="not-found" dangerouslySetInnerHTML={{__html: text.not_found_text}} />
    )
}

export default NotFound
