import '../../assets/scss/content/content.css';
import ContactsMe from "../../components/pages/contact/contactsme";
import {useEffect, useState} from 'react';
import ContentLoader from '../../components/core/loader';

const Contact = () => {
  const [showCover, setShowCover] = useState(true)
  useEffect(() => {
    
      setTimeout(() => {
          setShowCover(false)
      }, 2000)
  }, [])
  return (
    <main className="main">
    {!showCover ? (
        <>
            <ContactsMe  />
        </>
    ) : (
        <ContentLoader word='Contact.'/>
    )}
</main>
  )
}
export default Contact