import './index.css'
import text from './text.json'

const Template = ({data}) => {
    return (
        <div data-aos="fade-up" data-aos-duration="1000" className="template">
            <div className="template-desc">
                <div className="desc-container">
                    <span>{data.work?.side_title || text.more}</span>
                    <p>{data.work?.side_more_detail?.replace(/(<([^>]+)>)/ig, '')}</p>
                </div>
            </div>
            <div className="template-image">
                <img src={data.work?.side_more_image} alt="Saida Zahidova" />
            </div>
        </div>
    )
}

export default Template
