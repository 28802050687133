import '../../assets/scss/content/content.css'
import Hero from '../../components/pages/home/hero'
import Info from '../../components/pages/home/info'
import SelectedWorks from '../../components/pages/home/selected-works'
import EmailSection from '../../components/pages/home/email-section'
import {getHomePageData} from '../../stores/backend'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import ContentLoader from '../../components/core/loader'

const Home = () => {
    const dispatch = useDispatch()
    const homePageData = useSelector(state => state.backend.homePageData)
    // const isHomePageDataFetching = useSelector(state => state.backend.isHomePageDataFetching)

    const [showCover, setShowCover] = useState(true)

    const checkFirstVisit = !window.location.href.includes('home')
    const checkWorks = window.location.href.includes('#selected-works')

    useEffect(() => {
        dispatch(getHomePageData())
        setTimeout(() => {
            setShowCover(false)
        }, 2000)
    }, [])

    return (
        <main className="main">
            {!showCover ? (
                <>
                    <Hero data={homePageData} />
                    <Info data={homePageData} />
                    <SelectedWorks />
                    <EmailSection />
                </>
            ) : (
                <ContentLoader className={checkFirstVisit} word={checkWorks ? 'Selected <br/> works.' : 'Home.'}/>
            )}
        </main>
    )
}

export default Home
